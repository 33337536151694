<template>
  <div :class="[ 'categories-item', ...colorClasses, ]">
    <n-link :to="link" />
    <div class="content">
      <div v-if="item.avatar" class="img">
        <img :src="item.avatar" alt="category">
      </div>
      <div v-else-if="item.icon" class="img">
        <n-icon :icon="item.icon" />
      </div>
      <div class="title">{{ item.title }}</div>

      <n-icon icon="chevron-right" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CategoriesItem',
  props: {
    item: { type: Object, required: true, },
    index: { type: Number, default: 0, },
  },
  computed: {
    storeId() {
      return this.$route.query.store
    },
    forCreate() {
      return this.$route.query.create !== undefined
    },
    colorClasses() {
      return [
        { pink: this.index % 3 === 1, },
        { yellow: this.index % 3 === 2, },
      ]
    },
    link() {
      if (this.item.link) {
        return this.item.link
      }
      if (this.item.isParent) {
        return { name: 'categories.sub', params: { id: this.item.id, }, query: { store: this.storeId, create: this.forCreate ? null : undefined, }, }
      }
      if (this.forCreate) {
        return { name: 'products.create', params: { category: this.item, }, }
      }
      if (this.storeId) {
        return { name: 'stores.index', params: { id: this.storeId, }, query: { categoryId: this.item.id, }, }
      }
      return { name: 'products.list', query: { categoryId: this.item.id, }, }
    },
  },
}
</script>

<style scoped lang="scss">
.categories-item {
  border-radius: 5px;
  margin-bottom: 5px;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: rgba(116, 194, 86, 0.23);
  position: relative;

  .content {
    display: flex;
    align-items: center;
  }

  .img {
    width: 50px;
    margin-right: 36px;
    display: flex;
    justify-content: center;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    font-size: 18px;
    color: #000;
  }

  &.pink {
    background: #FBF1F1;
  }

  &.yellow {
    background: #FFF6D6;
  }

  &:last-child {
    margin-bottom: 0;
  }

  i {
    color: rgba(127, 127, 127, .5);
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
  }

  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
