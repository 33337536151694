<template>
  <div class="page-categories-sub">
    <back />
    <n-loader :loading="$var('load')" />
    <page-title :title="parent.title || ''" description="Выберете нужную вам подкатегорию товаров" />
    <div class="container">
      <categories-item v-if="!forCreate" :item="all" :index="0" />
      <categories-item v-for="item in categories" :key="item.id" :item="item" />
    </div>
  </div>
</template>

<script>
import CategoriesItem from '../components/CategoriesItem'

export default {
  name: 'PageCategoriesSub',
  components: { CategoriesItem, },
  data() {
    return {
      categories: [],
      parent: {},
    }
  },
  computed: {
    storeId() {
      return this.$route.query.store
    },
    forCreate() {
      return this.$route.query.create !== undefined
    },
    parentId() {
      return this.$route.params.id
    },
    all() {
      return {
        title: 'Все товары',
        link: this.storeId ? { name: 'stores.index', params: { id: this.storeId, }, query: { categoryId: this.parent.id, }, } :
          { name: 'products.list', query: { categoryId: this.parent.id, }, },
      }
    },
  },
  watch: {
    parentId() {
      this.load()
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      const api = $api.app.categories.get().filter({ parentId: this.parentId, })
      if (this.storeId) api.filter({ byStore: this.storeId, })
      if (this.forCreate) api.filter({ isForStore: $app.auth.user().hasStore, })
      const apis = [
        api,
        $api.app.categories.get(this.parentId),
      ]
      Promise.all(apis).then((responses) => {
        this.categories = responses[0].data.content
        this.parent = responses[1].data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-categories-sub {
}
</style>
